import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { isValidToken, setSession } from '../utils/jwt';
import { ActionMap, AuthState, AuthUser, IContract, JWTContextType } from '../@types/auth';
import axiosInstance from '../utils/axios';
import ContractSelectionDialog from "src/sections/auth/login/ContractSelectionDialog";

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  UpdateContracts = 'UPDATE_CONTRACTS',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    contracts: IContract[];
  };
  [Types.Login]: {
    user: AuthUser;
    contracts: IContract[];
    selectedContract?: IContract | null;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
    contracts: IContract[];
  };
  [Types.UpdateContracts]: {
    user: AuthUser;
    contracts: IContract[];
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  contracts: [],
  selectedContract: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        contracts: action.payload.contracts,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        contracts: action.payload.contracts,
        selectedContract: action.payload.selectedContract,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case 'UPDATE_CONTRACTS':
      return {
        ...state,
        contracts: action.payload.contracts,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const { id } = jwtDecode<{ id: string }>(accessToken);

          const response = await axiosInstance.get(`/users/getUserById/${id}`);
          const user = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: user,
              contracts: user.contracts,
            },
          });

          // setDialogOpen(true);
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              contracts: [],
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            contracts: [],
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (cpf: string, password: string) => {
    const response = await axiosInstance.post('/auth/signin', {
      cpf,
      password,
    });

    const { accessToken, user, contracts } = response.data.data;

    setSession(accessToken);

    if (contracts.length) {
      dispatch({
        type: Types.UpdateContracts,
        payload: {
          user,
          contracts: contracts,
        },
      });
    }

    dispatch({
      type: Types.Login,
      payload: {
        user,
        contracts: contracts,
        selectedContract: contracts.length === 1 ? contracts[0] : null,
      },
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axiosInstance.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
        contracts: [],
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const setContract = (contract?: IContract | null) => {
    dispatch({
      type: Types.Login,
      payload: {
        ...state,
        selectedContract: contract,
      },
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        setContract,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

