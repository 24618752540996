import { Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import moment from "moment";
import React from 'react';
import { ContractRole, IContract } from "src/@types/auth";

interface ContractSelectionDialogProps {
    open: boolean;
    contracts: IContract[];
    onClose: (selectedContract: IContract | null) => void;
}

const ContractSelectionDialog: React.FC<ContractSelectionDialogProps> = ({ open, contracts, onClose }) => {
    const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick") {
            onClose(null);
        }
    };

    const roleTransform = (role: ContractRole) => {
        switch (role) {
            case 'USER':
                return 'Titular';
            case 'DEPENDENT':
            case 'PROPOSAL_DEPENDENT':
                return 'Dependente';
            default:
                return '';
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle style={{ fontWeight: 'bold', fontSize: '10pt', textTransform: 'uppercase', letterSpacing: '0.1rem', color: 'grey' }} align="center">Selecione um contrato</DialogTitle>
            <DialogContent>
                <List>
                    {contracts.map((contract, index) => (
                        <React.Fragment key={contracts.indexOf(contract)}>
                            <ListItem button onClick={() => onClose(contract)}>
                                <ListItemText
                                    primary={contract.name || contract.role}
                                    secondary={
                                        <React.Fragment>
                                            {contract.role != 'LEAD' && (<Typography variant="subtitle2">{roleTransform(contract.role)} • Contrato nº: {contract.contract_number}</Typography>)}
                                            {contract.role == 'LEAD' && (<Typography variant="subtitle2">Expira em {moment(contract.access_expire_in).format('DD/MM/YYYY')}</Typography>)}
                                        </React.Fragment>
                                    }
                                    // secondary={`Registrado em ${moment(contract.date_issued).format('DD/MM/YYYY')}`}
                                    primaryTypographyProps={{ fontWeight: 'bold' }} />
                            </ListItem>
                            {index < contracts.length - 1 && <Divider />}
                        </React.Fragment>

                    ))}
                </List>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={() => onClose(null)}>Cancelar</Button>
                <Button onClick={handleConfirm} disabled={!setSelectedContract}>Confirmar</Button>
            </DialogActions> */}
        </Dialog>
    );
};

export default ContractSelectionDialog;