import { Container, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { CardDependent } from 'src/components/dependents/CardDependent';
import { FormDialog } from 'src/components/dependents/FormDialog';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';
import IPlan, { IDependent } from './dashboard/@types';
import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from "src/hooks/useAuth";
import { IContract } from "src/@types/auth";

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const ContentInfo = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'wrap',
  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
  borderRadius: '16px',
  padding: '20px 0px',
  mt: '1rem',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const getDependents = async (contract?: IContract) => {
  if (!contract) return [];

  try {

    const { data: dependentsData } = await axiosInstance.get<IDependent[]>(`/users/dependents/${contract.id}`);

    const activeDependents = dependentsData.filter(
      (dependent: IDependent) => dependent.active
      // TODO: Removendo:  && activeContractIds.includes(dependent.belongs_contract)
    );

    console.log(activeDependents);

    // Filtra os dependentes para excluir registros com 'recno' nulo ou vazio
    const filteredDependents = activeDependents.filter((dep) => dep.recno);

    const uniqueDependents: IDependent[] = filteredDependents
      .filter(
        (dep, index, self) =>
          index ===
          self.findIndex(
            (t) => t.name === dep.name && t.birthdate === dep.birthdate && t.recno === dep.recno
          )
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    return uniqueDependents;
  } catch (error) {
    console.error('Erro ao carregar dependentes:', error);
    return [];
  }
};

export function ManageDependents() {
  const { selectedContract } = useAuth();
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const [dependents, setDependents] = useState<IDependent[]>([]);
  const [dependentForEdit, setDependentForEdit] = useState<IDependent | null>();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [removeLoading, setRemoveLoading] = useState(false);

  const handleClickOpen = (dependent: IDependent) => {
    setDependentForEdit(dependent);
    setOpen(() => true);
  };

  const handleClose = () => {
    setOpen(false);
    setDependentForEdit(null);
  };

  const editDependent = (dependent: IDependent) => {
    setDependents((state) =>
      state.map((dependentState) => {
        if (dependentState.id === dependent.id) {
          return dependent;
        } else {
          return dependentState;
        }
      })
    );
  };

  const handlegetDependents = async () => {
    if (selectedContract) {
      setDependents(await getDependents(selectedContract));
      setRemoveLoading(true);
    }
  };

  useEffect(() => {
    handlegetDependents();
  }, []);

  return (
    <RootStyle>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <div style={{ display: 'flex' }}>
          <HeaderBreadcrumbs
            arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
            heading={`Meus Dependentes`}
            links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Meus Dependentes' }]}
          />
        </div>
        {removeLoading ? (
          <ContentStyle>
            <ContentInfo>
              <Grid container display="flex" justifyContent="center">
                {dependents.length > 0 ? (
                  dependents
                    .filter((dependent) => dependent.active)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .sort((a, b) => Number(b.already_updated) - Number(a.already_updated))
                    .map((dependent) => (
                      <CardDependent
                        key={dependent.id}
                        handleClickOpen={handleClickOpen}
                        dependent={dependent}
                      />
                    ))
                ) : (
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.grey[800]}
                    textAlign="center"
                    fontWeight="600"
                    fontSize="16px"
                  >
                    Não foi encontrado dependentes para este contrato
                  </Typography>
                )}
              </Grid>
            </ContentInfo>
          </ContentStyle>
        ) : (
          <LoadingScreen />
        )}
      </Container>
      {dependentForEdit && (
        <FormDialog
          open={open}
          onClose={handleClose}
          dependent={dependentForEdit}
          editDependent={editDependent}
        />
      )}
    </RootStyle>
  );
}

