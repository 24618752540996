import axios from 'axios';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useAuth from 'src/hooks/useAuth';
import { getDependents } from 'src/pages/ManageDependents';
import axiosInstance from 'src/utils/axios';

// Definição da interface para uma notificação
interface Notification {
  id: number;
  createdAt: string;
  title: string;
  body: string;
  batch: string;
  opened: boolean;
  type: string;
}

interface NotificationsContextData {
  notifications: Notification[];
  getNotifications: () => Promise<void>;
  markAsRead: (batch: string) => Promise<void>;
  removeNotification: (id: number) => void;
  hideNotification: (id: number) => void;
  dependentsUpdate: boolean;
  loadingNotifications: boolean;
}

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsContext = createContext<NotificationsContextData>(
  {} as NotificationsContextData
);

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [dependentsUpdate, setDependentsUpdate] = useState<boolean>(false);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(true);
  const { user } = useAuth();

  const handleCheckUpdateDependents = async () => {
    const dependents = await getDependents();
    let dependentUpdateNotify = false;
    dependents.map((dependent) => {
      if (!dependent.already_updated) {
        setDependentsUpdate(true);
        dependentUpdateNotify = true;
      }
      return dependent;
    });
    return dependentUpdateNotify;
  };

  const getNotifications = async () => {
    setLoadingNotifications(true);

    setLoadingNotifications(false);
  };

  const markAsRead = async (batch: string) => {
    // Implemente a lógica para marcar a notificação como lida
    if (batch !== '')
      await axios({
        method: 'PATCH',
        url: process.env.REACT_APP_ADMIN_APP_API + '/admin/notifications',
        headers: {
          'x-api-key': process.env.REACT_APP_PANEL_API_KEY || '',
          'Content-Type': 'application/json',
        },
        data: {
          userId: user.id,
          batch: batch,
        },
      })
        .then(async (response) => {
          toast.success('Notificação marcada como lida!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          await getNotifications(); // Recarrega as notificações após marcar como lida
        })
        .catch((error) => {
          console.error('Erro ao marcar notificação como lida', error);
          toast.error('Erro ao marcar notificação como lida');
        });
  };

  const removeNotification = useCallback((id: number) => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  const hideNotification = useCallback((id: number) => {
    setNotifications((currentNotifications) =>
      currentNotifications.map((notification) =>
        notification.id === id ? { ...notification, viewed: true } : notification
      )
    );
  }, []);

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        getNotifications,
        markAsRead,
        removeNotification,
        hideNotification,
        dependentsUpdate,
        loadingNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);

