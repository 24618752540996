import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import axiosInstance from 'src/utils/axios';
import moment from 'moment';
import Iconify from 'src/components/Iconify';
import { ModalPayments } from 'src/components/payments/ModalPayments';
import { AiOutlineDollar, AiOutlineClockCircle, AiOutlineCheckCircle } from 'react-icons/ai';

export interface Parcel {
  due_date: string;
  due_date_corR: string;
  installment: string;
  total: string;
  status: string;
  num: string;
  company: string;
  filial: string;
  prefix: string;
  type: string;
  bussines: string;
  barcode: string;
  barcode_line: string;
  copy_pix: string;
}

export function HistoricPayments() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');
  const [removeLoading, setRemoveLoading] = useState(false);
  const [contracts, setContrats] = useState([]);
  const [currentContract, setCurrentContract] = useState(user.contract_number);
  const [contractFilter, setContractFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [yearFilter, setYearFilter] = useState(`${new Date().getFullYear()}`);
  const [listHistoric, setListHistoric] = useState([]);
  const [nextPaym, setNextPaym] = useState<Parcel | null>(null);
  const [parcel, setParcel] = useState<Parcel | null>(null);
  const [loadingNextPaym, setLoadingNextPaym] = useState(false);

  let yearsFilter: string[] = [];

  const [open, setOpen] = useState(false);
  const handleClickOpen = (i: Parcel) => {
    setOpen(() => true);
    setParcel(i);
  };

  const handleClose = () => {
    setOpen(false);
  };

  yearsFilter.push(String(new Date().getFullYear() + 1));

  for (let i = 0; i < 6; i++) {
    yearsFilter.push(String(new Date().getFullYear() - i));
  }
  const getContracts = async () => {
    let { data } = await axiosInstance.get(`/users/contracts`);
    setContrats(data.filter((i: any) => i.status === '1'));
    setCurrentContract(data[0].contract_number);
  };
  const nextPayment = async () => {
    setLoadingNextPaym(true);
    let { data } = await axiosInstance.get(
      `/users/listHistoric/${user.id}?contract_number=${currentContract}&year=${String(
        new Date().getFullYear()
      )}`
    );
    if (data.length === 0) {
      setNextPaym(null);
    } else {
      setNextPaym(data[0]);
    }

    setLoadingNextPaym(false);
  };
  const getHistoric = async () => {
    setRemoveLoading(false);
    let { data } = await axiosInstance.get(
      `/users/listHistoric/${user.id}?contract_number=${currentContract}&year=${yearFilter}`
    );
    statusFilter === 'PAGO'
      ? setListHistoric(data.filter((i: any) => i.status === 'PAGO'))
      : statusFilter === 'EM_ABERTO'
        ? setListHistoric(data.filter((i: any) => i.status === 'EM ABERTO'))
        : setListHistoric(data);

    setRemoveLoading(true);
  };
  const LatePayments = listHistoric.filter(
    (i: any) => moment(new Date()).diff(moment(i.due_date), 'days') > 45 && i.status !== 'PAGO'
  );

  useEffect(() => {
    nextPayment();
    getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHistoric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearFilter, contractFilter, statusFilter]);

  return (
    <Page title="Meu Plano Sempre">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div style={{ display: 'flex' }}>
          <HeaderBreadcrumbs
            arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
            heading={`Histórico Financeiro`}
            links={[
              { name: 'Início', href: PATH_DASHBOARD.root },
              { name: 'Histórico Financeiro' },
            ]}
          />
        </div>
        <Divider sx={{ marginBottom: '20px' }} />

        {contracts.length > 1 && (
          <div style={{ display: 'flex' }}>
            <select
              onChange={(e) => {
                setContractFilter(e.target.value);
                setCurrentContract(e.target.value);
              }}
              name="setContract"
              style={{
                marginBottom: '20px',
                padding: '10px',
                borderRadius: '5px',
                width: '250px',
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
            >
              {contracts.map((i: any) => (
                <option key={i.id} value={i.contract_number}>
                  {i.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {nextPaym !== null && (
          <Card style={{ width: '100%', maxWidth: '420px', padding: '0 10px', margin: '0 auto' }}>
            <Typography
              color={theme.palette.grey[600]}
              textTransform="uppercase"
              fontWeight="700"
              fontSize="12px"
              lineHeight="18px"
              letterSpacing="1.2px"
              margin="20px 0"
            >
              Próximo pagamento
            </Typography>
            <Divider sx={{ borderStyle: 'dashed', marginBottom: '10px' }} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {loadingNextPaym ? (
                <div
                  style={{
                    width: '100%',
                    height: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                  <Typography
                    color={theme.palette.grey[600]}
                    textTransform="uppercase"
                    fontWeight="700"
                    fontSize="12px"
                    lineHeight="18px"
                    letterSpacing="1.2px"
                    margin="20px 0"
                  >
                    Carregando próximo pagamento...
                  </Typography>
                </div>
              ) : (
                <>
                  <Card
                    sx={{
                      width: '100%',
                      maxWidth: '400px',
                      margin: '20px',
                      padding: '20px',
                      backgroundColor: theme.palette.grey[100],
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <Typography
                        variant="body2"
                        fontWeight="400"
                        color={theme.palette.grey[800]}
                        fontSize="14px"
                        margin="10px"
                      >
                        Vencimento
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="700"
                        color={theme.palette.grey[800]}
                        fontSize="14px"
                        margin="10px 0"
                      >
                        {moment(nextPaym?.due_date).format('DD/MM/YYYY')}
                      </Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <Typography
                          variant="body2"
                          fontWeight="400"
                          color={theme.palette.grey[800]}
                          fontSize="14px"
                          margin="10px"
                        >
                          Valor
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight="700"
                          color={theme.palette.grey[800]}
                          fontSize="14px"
                          margin="10px 0"
                        >
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(Number(nextPaym?.total))}
                        </Typography>
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                          borderRadius: '5px',
                          backgroundColor:
                            nextPaym?.status === 'PAGO'
                              ? theme.palette.success.lighter
                              : moment(new Date()).diff(moment(nextPaym?.due_date), 'days') > 45
                                ? theme.palette.error.lighter
                                : theme.palette.warning.lighter,
                          color:
                            nextPaym?.status === 'PAGO'
                              ? theme.palette.success.dark
                              : moment(new Date()).diff(moment(nextPaym?.due_date), 'days') > 45
                                ? theme.palette.error.dark
                                : theme.palette.warning.dark,
                          padding: '10px',
                          height: '30px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}
                      >
                        {nextPaym?.status !== 'PAGO' && (
                          <AiOutlineClockCircle style={{ width: 16, height: 16, marginRight: 3 }} />
                        )}
                        {nextPaym?.status}
                      </div>
                    </div>
                    {nextPaym?.status !== 'PAGO' && (
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        startIcon={<AiOutlineDollar />}
                        onClick={() => {
                          handleClickOpen(nextPaym);
                        }}
                      >
                        Pagar
                      </Button>
                    )}
                  </Card>
                  {LatePayments.length !== 0 && (
                    <Card
                      sx={{
                        width: '100%',
                        maxWidth: '400px',
                        margin: '20px',
                        backgroundColor: theme.palette.error.lighter,
                        display: 'flex',
                        borderRadius: '8px',
                      }}
                    >
                      <div>
                        <Iconify
                          icon="ant-design:exclamation-circle-filled"
                          color={theme.palette.error.main}
                          margin="10px"
                          width="24px"
                          height="24px"
                        />
                      </div>
                      <div>
                        <Typography
                          variant="subtitle2"
                          fontWeight="600"
                          color={theme.palette.error.darker}
                          fontSize="14px"
                          margin="10px 0 0"
                        >
                          Você possui parcelas atrasadas!
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight="400"
                          color={theme.palette.error.darker}
                          fontSize="14px"
                          margin="10px 0"
                        >
                          Confira no histórico abaixo.
                        </Typography>
                      </div>
                    </Card>
                  )}
                </>
              )}
            </div>
          </Card>
        )}

        <Card
          sx={{
            marginTop: '20px',
            padding: '20px',
          }}
        >
          <Typography
            color={theme.palette.grey[600]}
            textTransform="uppercase"
            fontWeight="700"
            fontSize="12px"
            lineHeight="18px"
            letterSpacing="1.2px"
            margin="20px"
          >
            Histórico de pagamentos
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: `${!isMobile ? 'row' : 'column'}`,
              alignItems: 'center',
              justifyContent: 'left',
              margin: '20px',
            }}
          >
            <Typography
              variant="subtitle2"
              color={theme.palette.grey[600]}
              textAlign="left"
              fontWeight="600"
              fontSize="14px"
              marginRight="20px"
            >
              Filtrar pagamentos por
            </Typography>
            <div style={{ display: 'flex' }}>
              <select
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                }}
                name="filtroStatus"
                style={{
                  marginRight: '20px',
                  padding: '10px',
                  borderRadius: '5px',
                  width: `${!isMobile ? '150px' : '50%'}`,
                  borderColor: theme.palette.grey[500],
                  color: theme.palette.grey[500],
                }}
              >
                <option value="" hidden>
                  Status
                </option>
                <option
                  value="all"
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: '14px',
                    fontWeight: '600',
                    margin: '10px',
                  }}
                >
                  <span style={{ padding: '10px' }}>Todo o histórico financeiro</span>
                </option>
                <option
                  value="EM_ABERTO"
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: '14px',
                    fontWeight: '600',
                    margin: '10px',
                  }}
                >
                  Parcelas em aberto
                </option>
                <option
                  value="PAGO"
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: '14px',
                    fontWeight: '600',
                    margin: '10px',
                  }}
                >
                  Parcelas pagas
                </option>
              </select>
              <select
                onChange={(e) => {
                  setYearFilter(e.target.value);
                }}
                name="filtroYear"
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  width: `${!isMobile ? '150px' : '50%'}`,
                  borderColor: theme.palette.grey[500],
                  color: theme.palette.grey[500],
                }}
              >
                {yearsFilter.map((i: string) => (
                  <option key={i} value={i} selected={String(new Date().getFullYear()) === i}>
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            {removeLoading ? (
              listHistoric.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '12.5%', textAlign: 'center' }}>
                          Mês da Parcela
                        </TableCell>
                        <TableCell style={{ width: '25%', textAlign: 'center' }}>
                          Data de vencimento
                        </TableCell>
                        <TableCell style={{ width: '25%', textAlign: 'center' }}>
                          Valor do pagamento
                        </TableCell>
                        <TableCell style={{ width: '25%', textAlign: 'center' }}>
                          Status do pagamento
                        </TableCell>
                        <TableCell style={{ width: '12.5%', textAlign: 'center' }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listHistoric
                        .sort(function (a: any, b: any) {
                          if (a.status < b.status) {
                            return -1;
                          }
                          if (a.status > b.status) {
                            return 1;
                          }
                          return 0;
                        })
                        .sort(function (a: any, b: any) {
                          if (a.due_date_corR > b.due_date_corR) {
                            return -1;
                          }
                          if (a.due_date_corR < b.due_date_corR) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((i: Parcel) => (
                          <TableRow key={i.installment}>
                            <TableCell style={{ textAlign: 'center' }}>
                              {moment(i.due_date_corR).format('MMM/YYYY').toUpperCase()}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {moment(i.due_date).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(Number(i.total))}
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  width: '100px',
                                  borderRadius: '5px',
                                  backgroundColor:
                                    i.status === 'PAGO'
                                      ? theme.palette.success.lighter
                                      : moment(new Date()).diff(moment(i.due_date), 'days') > 45
                                        ? theme.palette.error.lighter
                                        : theme.palette.warning.lighter,
                                  color:
                                    i.status === 'PAGO'
                                      ? theme.palette.success.dark
                                      : moment(new Date()).diff(moment(i.due_date), 'days') > 45
                                        ? theme.palette.error.dark
                                        : theme.palette.warning.dark,
                                  padding: '5px',
                                  margin: '0 auto',
                                  height: '30px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: '12px',
                                }}
                              >
                                {i.status !== 'PAGO' ? (
                                  <AiOutlineClockCircle
                                    style={{ width: 20, height: 20, marginRight: 3 }}
                                  />
                                ) : (
                                  <AiOutlineCheckCircle
                                    style={{ width: 20, height: 20, marginRight: 3 }}
                                  />
                                )}
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                  {i.status !== 'PAGO' &&
                                    moment(new Date()).diff(moment(i.due_date), 'days') > 45
                                    ? 'EM ATRASO'
                                    : i.status}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              {i.status !== 'PAGO' ? (
                                <Button
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  startIcon={<AiOutlineDollar />}
                                  onClick={() => handleClickOpen(i)}
                                >
                                  Pagar
                                </Button>
                              ) : (
                                ''
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  variant="subtitle2"
                  color={theme.palette.grey[800]}
                  textAlign="center"
                  fontWeight="600"
                  fontSize="16px"
                >
                  Não foi encontrado histórico de pagamentos para o ano selecionado
                </Typography>
              )
            ) : (
              <></>
            )}
          </div>
          {!removeLoading ? (
            <div
              style={{
                width: '100%',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
              <Typography
                color={theme.palette.grey[600]}
                textTransform="uppercase"
                fontWeight="700"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="1.2px"
                margin="20px 0"
              >
                Carregando pagamentos...
              </Typography>
            </div>
          ) : (
            <></>
          )}
        </Card>
        {parcel && (
          <ModalPayments
            open={open}
            onClose={handleClose}
            parcel={parcel}
            userId={user.id}
            currentContract={currentContract}
          />
        )}
      </Container>
    </Page>
  );
}

