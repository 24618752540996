import { Box, Drawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import IPlan from 'src/pages/dashboard/@types';
import axiosInstance from 'src/utils/axios';
import Logo, { LogoCollapse } from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import { NAVBAR } from '../../../config';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';
import cssStyles from '../../../utils/cssStyles';
import CollapseButton from './CollapseButton';
import navConfig from './NavConfig';
import NavbarAccount from './NavbarAccount';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {
  const theme = useTheme();

  const { user, selectedContract } = useAuth();

  const [hasOnlyJazigo, setHasOnlyJazigo] = useState(false);

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse } = useCollapseDrawer();

  const getContract = async () => {
    try {
      const response = await axiosInstance.get(`/users/contracts`);
      const contracts = response.data;

      // Filtra apenas contratos ativos
      const activeContracts = contracts.filter((contract: IPlan) => contract.active);

      // Verifica se existe apenas um contrato ativo e se esse contrato é do tipo "JAZIGO"
      const isOnlyJazigo = activeContracts.length === 1 && activeContracts[0].type_contract === 'C';

      // Atualiza a variável de estado
      setHasOnlyJazigo(isOnlyJazigo);
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os contratos:', error);
    }
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // getContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={0}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            margin: 0,
          }}
        >
          {isCollapse ? <LogoCollapse /> : <Logo />}

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}

          {isDesktop && isCollapse && (
            <div style={{ position: 'absolute', top: 0, left: '50px' }}>
              <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            </div>
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfig(selectedContract?.role, hasOnlyJazigo)} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

