import { ContractRole } from "src/@types/auth";
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} />;

const ICONS = {
  home: getIcon('home'),
  tomanager: getIcon('toManage'),
  historic: getIcon('historic'),
  contract: getIcon('contract'),
  card: getIcon('card'),
  serviceUnits: getIcon('serviceUnits'),
  benefits: getIcon('benefits'),
  attendance: getIcon('attendance'),
  settings: getIcon('settings'),
  notifications: getIcon('notifications'),
  faq: getIcon('faq'),
};

function navConfig(role: ContractRole | undefined, hasOnlyJazigo: boolean) {
  if (role === 'USER') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Início',
            path: PATH_DASHBOARD.general.app,
            icon: ICONS.home,
          },
          {
            title: 'Meus Dependentes',
            path: PATH_DASHBOARD.user.dependents,
            icon: ICONS.tomanager,
          },
          {
            title: 'Histórico Financeiro',
            path: PATH_DASHBOARD.general.historic,
            icon: ICONS.historic,
          },
          {
            title: 'Meu Plano Sempre',
            path: PATH_DASHBOARD.general.contract,
            icon: ICONS.contract,
          },
          ...(hasOnlyJazigo
            ? []
            : [
              {
                title: 'Carteira Digital Sempre',
                path: PATH_DASHBOARD.general.card,
                icon: ICONS.card,
              },
            ]),
          {
            title: 'Onde estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },

          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
          {
            title: 'Notificações',
            path: PATH_DASHBOARD.user.notifications,
            icon: ICONS.notifications,
          },
          {
            title: 'Configurações',
            path: PATH_DASHBOARD.user.settings,
            icon: ICONS.settings,
          },
        ],
      },
    ];
  }

  if (role === 'DEPENDENT') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Início',
            path: PATH_DASHBOARD.general.app,
            icon: ICONS.home,
          },
          {
            title: 'Histórico Financeiro',
            path: PATH_DASHBOARD.general.historic,
            icon: ICONS.historic,
          },
          ...(hasOnlyJazigo
            ? []
            : [
              {
                title: 'Carteira Digital Sempre',
                path: PATH_DASHBOARD.general.card,
                icon: ICONS.card,
              },
            ]),
          {
            title: 'Onde estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },
          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
          {
            title: 'Notificações',
            path: PATH_DASHBOARD.user.notifications,
            icon: ICONS.notifications,
          },
          {
            title: 'Configurações',
            path: PATH_DASHBOARD.user.settings,
            icon: ICONS.settings,
          },
        ],
      },
    ];
  }

  if (role === 'LEAD') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Início',
            path: PATH_DASHBOARD.general.app,
            icon: ICONS.home,
          },
          {
            title: 'Onde estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },
          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
          {
            title: 'Notificações',
            path: PATH_DASHBOARD.user.notifications,
            icon: ICONS.notifications,
          },
          {
            title: 'Configurações',
            path: PATH_DASHBOARD.user.settings,
            icon: ICONS.settings,
          },
        ],
      },
    ];
  }

  if (role === 'PROPOSAL_USER') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Início',
            path: PATH_DASHBOARD.general.app,
            icon: ICONS.home,
          },
          {
            title: 'Meus Dependentes',
            path: PATH_DASHBOARD.user.dependents,
            icon: ICONS.tomanager,
          },
          {
            title: 'Meu Plano Sempre',
            path: PATH_DASHBOARD.general.contract,
            icon: ICONS.contract,
          },
          {
            title: 'Onde estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },
          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
          {
            title: 'Notificações',
            path: PATH_DASHBOARD.user.notifications,
            icon: ICONS.notifications,
          },
          {
            title: 'Configurações',
            path: PATH_DASHBOARD.user.settings,
            icon: ICONS.settings,
          },
        ],
      },
    ];
  }

  return [];
}

export default navConfig;
