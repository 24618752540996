import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useLecupon } from 'src/hooks/useLecupon';
import CardPlan from 'src/sections/@dashboard/general/app/CardPlan';
import CarrouselBanners from 'src/sections/@dashboard/general/app/CarrouselBanners';
import { CarrouselButtons } from 'src/sections/@dashboard/general/app/CarrouselButtons';
import CarrouselNews from 'src/sections/@dashboard/general/app/CarrouselNews';
import { FaqsPreview } from 'src/sections/@dashboard/general/app/FaqsPreview';
import { UserProgress } from 'src/sections/@dashboard/general/app/UserProgress';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import { fetchToken } from 'src/utils/firebase';

// ----------------------------------------------------------------------

async function callCrc() {
  try {
    const a = document.createElement('a');
    a.href = 'tel:8440002002';
    a.click();
  } catch (err) {
    console.log('Não foi possível chamar o crc');
  }
}

export default function GeneralApp() {
  const { user, selectedContract } = useAuth();
  const { getLecupon } = useLecupon();
  const theme = useTheme();

  const NameFull = user?.name;
  const { themeStretch } = useSettings();

  useEffect(() => {
    fetchToken(user);
    getLecupon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Início: ">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h5" color={theme.palette.grey[800]}>
          Olá, <span style={{ color: theme.palette.primary.main }}>{NameFull}</span>! <br /> Seja
          bem-vindo(a) à área do Cliente Sempre!
        </Typography>

        <Typography
          variant="subtitle1"
          color={theme.palette.grey[800]}
          sx={{ marginTop: '2.25rem', marginBottom: '1.25rem' }}
        >
          Confira o que você pode fazer por aqui.
        </Typography>

        <CarrouselButtons />

        {selectedContract?.role === 'LEAD' && (
          <Box>
            <Typography variant="subtitle1" sx={{ marginTop: '2.25rem', marginBottom: '1.25rem' }}>
              Quer ter acesso amplo ao portal?
            </Typography>
            <Button variant="contained" color="secondary" fullWidth onClick={callCrc}>
              Torne-se um cliente
            </Button>
          </Box>
        )}

        {selectedContract?.role === 'LEAD' && (
          <Box mt="20px">
            <a href="https://www.planosempre.com.br" target="_blank" rel="noreferrer">
              <img src="/assets/leads/planos.png" alt="Venha fazer parte do Sempre do Seu Jeito" />
            </a>
          </Box>
        )}

        {selectedContract?.role === 'USER' && <UserProgress />}

        {selectedContract?.role === 'USER' || (selectedContract?.role === 'PROPOSAL_USER' && <CardPlan />)}

        <CarrouselBanners />

        <CarrouselNews />

        <FaqsPreview />
      </Container>
    </Page>
  );
}

