import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { FaqDetails } from 'src/sections/@dashboard/faq/FaqDetails';
import axiosInstance from 'src/utils/axios';

interface Faq {
  answer: string;
  category_faq_id: string;
  id: string;
  order: number;
  question: string;
  title: string;
}

interface Category {
  id: string;
  order: number;
  category_name: string;
  faq: Faq[];
  presignedUrl: string | null;
}

export function FaqsPreview() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { selectedContract } = useAuth();

  const [categories, setCategories] = useState<Category[]>([]);

  async function getCategories() {
    const response = await axiosInstance.get<Category[]>(
      `/users/faq/categories?filter=${selectedContract?.role === 'LEAD' ? 'Lead' : 'Cliente'}`
    );

    setCategories(response.data);
  }

  async function callCrc() {
    try {
      const a = document.createElement('a');
      a.href = 'tel:8440002002';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  async function callCrcWhatsapp() {
    try {
      const a = document.createElement('a');
      a.href = 'https://wa.me/558440002002';
      a.target = '_blank';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={themeStretch ? false : 'lg'} sx={{ px: 0 }}>
      <Divider sx={{ marginBottom: '20px' }} />

      <Box>
        <Typography
          variant="h6"
          color={theme.palette.grey[800]}
          sx={{
            fontWeight: 600,
            fontSize: '1.12rem',
            lineHeight: '1.75rem',
            marginBottom: '0.75rem',
          }}
        >
          Dúvidas frequentes
        </Typography>

        <Typography
          color={theme.palette.grey[600]}
          sx={{
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: '1.25rem',
          }}
        >
          Em um momento tão delicado como a perda de um ente querido, muitas vezes ficamos sem saber
          quais providências devemos tomar. Nós entendemos você! É por isso que o Plano Sempre está
          pronto para lhe ajudar. Tire todas as dúvidas e saiba o que fazer na hora do falecimento
          de um ente querido.
        </Typography>
      </Box>

      <Box sx={{ marginTop: '1.5rem' }}>
        {categories
          .slice(0, 2)
          .filter((category) => category.faq.length > 0)
          .map((category) => (
            <Accordion
              key={category.id}
              sx={{
                '&:before': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <AccordionSummary
                sx={{ border: 'none', display: 'flex', alignItems: 'center' }}
                expandIcon={<Iconify icon="ep:arrow-down-bold" />}
              >
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  {category.presignedUrl ? (
                    <img
                      src={category.presignedUrl}
                      alt=""
                      style={{ width: '40px', height: '40px', borderRadius: '8px' }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        background: '#184981',
                        borderRadius: '8px',
                      }}
                    />
                  )}
                  <Typography
                    color={theme.palette.primary.main}
                    sx={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}
                  >
                    {category.category_name}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {category.faq.map((faq) => (
                  <FaqDetails key={faq.id} faq={faq} />
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      <Box
        sx={{
          width: '100%',
          marginTop: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Link
          to="/dashboard/faq"
          style={{
            width: '100%',
            maxWidth: '400px',
            margin: '0 auto',
            textDecoration: 'none',
            padding: '0.5rem 0.8rem',
            backgroundColor: theme.palette.primary.main,
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '22px',
            color: '#FFFFFF',
            borderRadius: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Consultar todas as dúvidas
          <Iconify icon={'ep:arrow-right-bold'} color="#FFFFFF" />
        </Link>
      </Box>

      <Card
        sx={{
          width: '100%',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="/assets/illustrations/headset.png" alt="entre em contato" />
          <Box sx={{ paddingLeft: '1.5rem' }}>
            <Typography color={theme.palette.secondary.main}>Precisa de ajuda?</Typography>
            <Typography>
              Você pode falar diretamente com a nossa central de atendimento ao cliente.
            </Typography>
          </Box>
        </Box>

        <Button
          fullWidth
          variant="contained"
          sx={{ background: theme.palette.secondary.main, marginTop: '1rem', maxWidth: '400px' }}
          onClick={callCrcWhatsapp}
        >
          Fale conosco pelo whatsapp
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          sx={{ marginTop: '1rem', maxWidth: '400px' }}
          onClick={callCrc}
        >
          Fale conosco pelo telefone
        </Button>
      </Card>
    </Container>
  );
}
